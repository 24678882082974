import React, { useEffect } from "react";
import { navigate } from "gatsby";

export default function NotFoundPage() {
  useEffect(() => {
    navigate("/"); // redirecting to home page
  }, []);

  return (
    <div className="pa4">
      <h1 className="white">(404) NotFound Page</h1>
      <h2 className="white">Redirecting back to home page...</h2>
      <a href="/" className="white">If you are not redirected, click here to go to the home page.</a>
    </div>
  );
}
